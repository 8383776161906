<template>
  <div class="yt-tab">
    <div
      class="yt-tab__item"
      :class="{
        active: +selectedIndex === index,
        divider: tabs.length > 2 && index,
      }"
      :key="index"
      v-for="(tab, index) in tabs"
      @click="$emit('select', index)"
    >
      <svg-inline v-if="tab.icon" class="yt-tab__item__icon">
        <Icon :icon="tab.icon" :color="tab.iconColor || '#121212'" />
      </svg-inline>
      <span v-text="tab.label"></span>
    </div>

    <div class="yt-tab__selected" :style="selectedStyle"></div>
  </div>
</template>

<script>
import SvgInline from "@/components/ui-kit/svg-inline/index.vue";
import Icon from "@/components/icons/index.vue";

export default {
  name: "yt-tab",
  components: {
    SvgInline,
    Icon,
  },
  props: {
    tabs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedIndex: {
      type: [String, Number],
      default: 0,
    },
  },
  computed: {
    selectedStyle() {
      return {
        transform: `translateX(${100 * +this.selectedIndex}%)`,
        width: `calc((100% - 8px) / ${this.tabs.length})`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$b: ".yt-tab";

#{$b} {
  position: relative;
  background: var.$gray-light;
  padding: 4px;
  border-radius: 12px;
  display: flex;
  font-family: var.$font-family-sf-pro-text;
  width: 100%;

  &__item {
    @include mixin.clickable();
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    padding: 11px 0;
    color: var.$black;
    cursor: pointer;
    @media (min-width: var.$size-md) {
      font-size: 13px;
    }

    & > * {
      @include mixin.font-semibold();
      z-index: 1;
    }

    &__icon {
      width: 20px;
    }

    &.active + div:before {
      display: none;
    }

    &:not(.active) {
      &:not(:hover).divider:before {
        content: "";
        position: absolute;
        left: -0.5px;
        width: 1px;
        height: 20px;
        background: var.$gray-dark;
      }

      &:hover {
        background: var.$gray-pale;

        & + div:before {
          display: none;
        }
      }
    }
  }

  &__selected {
    position: absolute;
    height: auto;
    background: var.$white;
    bottom: 4px;
    left: 4px;
    top: 4px;
    border-radius: 8px;
    transition: var.$transition-fast;
  }
}
</style>
