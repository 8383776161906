import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";

const axiosUser = {
  userInfo(){
    return axiosYtApiV2.get("/user");
  },
  favouriteAdd({ type = "tour", id }) {
    return axiosYtApiV2.post("/user/favourite/add", {
      type,
      id,
    });
  },
  favouriteRemove({ type = "tour", id }) {
    return axiosYtApiV2.post("/user/favourite/remove", {
      type,
      id,
    });
  },

  UpdateFields(data) {
    return axiosYtApiV2.post(`/user/update`, data);
  },

  getBonusLevel() {
    return axiosYtApiV2.get("/user/bonus-level");
  },
  getLoyalty(currency) {
    return axiosYtApiV2.get("/user/loyalty", { params: { currency } });
  },
  getLoyaltyNotifications(currency) {
    return axiosYtApiV2.get("/user/loyalty/notifications", { params: { currency } });
  },
};

export { axiosUser };
