import { axiosYtApiV2 } from "@/services/axios-yt-api-v2.js";

const axiosOrders = {
  orderAdd(type, data) {
    return axiosYtApiV2.post(`orders/${type}`, data);
  },
  //Обновление данных пользователя не авторизованного (из сессии)
  updateUserData(data) {
    return axiosYtApiV2.post(`orders/user`, data);
  },
  //Получает данные пользователя не авторизованного (из сессии)
  getUserData() {
    return axiosYtApiV2.get(`orders/user`);
  },
};

export { axiosOrders };
