<template>
  <no-ssr>
    <div class="yt_input"
      :class="{
        'yt_input--phone': type == 'phone',
        'yt_input--date': type == 'date',
        'yt_input--solo': solo || rightIcons,
        'yt_input--textarea': type == 'textarea',
        'yt_input--hasvalue': localValue || getPlaceholder || type == 'date',
        'yt_input--disabled': disabled,
        'yt_input--error': error,
      }">
      <div class="yt_input-container"
        :ref="containerRefId"
        :tabindex="disabled ? null : 0"
        v-on:focus="$refs[refId].focus()">

        <div class="yt_input-icons yt_input-icons--right" ref="rightIcons" v-if="rightIcons">
          <div class="yt_input-icon yt_input-icon--search" v-if="showSearch">
            <Icon icon="input-search" />
          </div>
        </div>

        <div class="yt_input-field">
          <textarea class="yt_input-form yt_input-form--textarea"
            v-if="type == 'textarea'"
            :value="localValue"
            @input="setVal($event.target.value)"
            :ref="refId"
            :disabled="disabled"
            :maxlength="maxlength"
            @change="$emit('change', $event.target.value)">
          </textarea>

          <vue-tel-input class="yt_input-form"
            v-else-if="type == 'phone'"
            :ref="refId"
            v-model="value"
            v-bind="vueTelInputOptions"
            :value="localValue"
            :disabled="disabled"
            :placeholder="placeholder"
            :defaultCountry="getDefaultCountry"
            @input="setVal($event)"
            @country-changed="onCountryChanged"
            @change="$emit('change', $event)">
            <template v-slot:arrow-icon="{ open }">
              <Icon
                class="input-icon-dropdown"
                icon="arrow-dropdown"
                :color="open ? '#9AAE2D' : '#9999A9'"
              />
            </template>
          </vue-tel-input>

          <input class="yt_input-form"
            v-else
            :type="hidePassword || type == 'phone' ? 'text' : type"
            :ref="refId"
            :value="localValue"
            :disabled="disabled"
            :placeholder="placeholder"
            @input="setVal($event.target.value)"
            :maxlength="maxlength"
            @change="$emit('change', $event.target.value)">

          <div class="yt_input-label" v-if="type != 'phone'">
            {{ this.label }}
          </div>
        </div>

        <div class="yt_input-icons" ref="leftIcons">

          <div class="yt_input-icon yt_input-icon--dropdown" v-if="isDropdown && !isTextarea">
            <Icon icon="input-dropdown" :color="disabled ? '#9999A9' : '#121212'"/>
          </div>

          <div class="yt_input-icon yt_input-icon--eye"
            v-if="!disabled && type == 'password' && !isDropdown && !isTextarea"
            @click="hidePassword = !hidePassword">
            <Icon :icon="hidePassword ? 'input-eye-off' : 'input-eye'"/>
          </div>

          <div class="yt_input-icon yt_input-icon--clear"
            v-if="clear && !disabled && !isDropdown && !isTextarea"
            @click="clearValue">
            <Icon icon="input-close"/>
          </div>

        </div>
      </div>
      <div class="yt_input-hint" v-if="hint">{{ hint }}</div>
    </div>
  </no-ssr>
</template>

<script>
import Icon from '@/components/icons/index.vue';
import noSSR from "vue-no-ssr";
import { i18n } from "@/plugins/i18n";
//import { VueTelInput } from "vue-tel-input";
//import "vue-tel-input/dist/vue-tel-input.css";
const VueTelInput = () =>
  Promise.all([
    import(/* webpackChunkName: "chunk-vue-tel-input" */ "vue-tel-input"),
    import(
      /* webpackChunkName: "chunk-vue-tel-input" */ "vue-tel-input/dist/vue-tel-input.css"
      ),
  ]).then(([{ VueTelInput }]) => VueTelInput);
export default {
  components: {
    Icon,
    VueTelInput,
    "no-ssr": noSSR,
  },
  props: {
    value: {
      type: String,
      default: '',
      reqiured: true,
    },
    label: {
      type: String,
      default: 'Label',
    },
    solo: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return [
          'text',
          'password',
          'phone',
          'textarea',
          'dropdown',
          'date',
        ].includes(value);
      },
    },
    error: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    maxlength: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      insideVal: '',
      isMounted: false,
      hidePassword: false,
      totalIconsWidthPX: 0,
      rightIconsWidthPX: 0,
      phoneCountry: null,
    }
  },
  model: {
    prop: "value",
    event: "input",
  },
  computed: {
    getDefaultCountry(){
      return i18n.locale === "ru" ? "ru" : "us";
    },
    refId() {
      return 'ytInputEl' + this._uid;
    },
    containerRefId() {
      return 'ytInputContainerEl' + this._uid;
    },
    localValue: {
      get() {
        if (this.value == undefined) {
          return this.insideVal;
        }
        return this.value;
      },
      set(value) {
        if (this.value == undefined) {
          this.insideVal = value;
          return;
        }
        this.$emit('input', value);
      },
    },
    getPlaceholder() {
      if (this.placeholder) {
        return this.placeholder;
      }
      if (this.isMounted) {
        return this.$refs[this.refId]?.placeholder;
      }
      return null;
    },
    rightIcons() {
      return this.search;
    },
    isDropdown() {
      return this.type == 'dropdown';
    },
    isTextarea() {
      return this.type == 'textarea';
    },
    showSearch() {
      return (
        this.search &&
        !this.disabled &&
        !this.isTextarea &&
        this.type != 'phone' &&
        this.type != 'date'
      );
    },
    vueTelInputOptions() {
      return {
        mode: "international",
        validCharactersOnly: true,
        autoDefaultCountry: false,
        inputOptions: {
          // TODO correct masking of the phone number
          maxlength: this.phoneCountry?.iso2 == "RU" ? 16 : 20,
          showDialCode: true,
          autocomplete: true,
        },
        dropdownOptions: {
          showSearchBox: true,
          showFlags: true,
        },
      };
    },
  },
  methods: {
    setVal(value) {
      this.localValue = value;
    },
    clearValue() {
      this.$emit('change', '');
      this.localValue = '';
    },
    onCountryChanged(country) {
      this.phoneCountry = country;
    },
  },
  mounted() {
    this.isMounted = true;
  },
};

</script>

<style lang="scss" scoped>
// normalize
.yt_input {
  --gray: #9999A9;
  --black: #121212;
  --red: #F84565;
  font-family: var.$font-family-sf-pro-text;
  width: 343px;
}

input:focus, input:hover, textarea {
  box-shadow: unset;
}

.yt_input-form::v-deep .vti {
  &__input {
    &:focus {
      outline: none;
    }
  }
  &__dropdown {
    padding: 0;
    background-color: unset;
    position: unset;

    &-item {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      border-radius: 6px;
      padding: 11px 12px;

      & .by {
        padding: 0;
      }

      & strong {
        font-family: var.$font-family-sf-pro-text;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.02em;
        color: var(--black);
      }

      & span {
        font-family: var.$font-family-sf-pro-text;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.02em;
      }

      &.highlighted {
        background-color: #f6f7fa;
      }
    }

    &-list {
      top: 56px !important;
      bottom: unset;
      padding: 8px;
      box-shadow: 1px 4px 20px rgb(153 153 169 / 35%);
      border-radius: 12px;
      border: 0px;
      font-family: var.$font-family-sf-pro-text;
      width: 100%;
      box-sizing: border-box;

      & input {
        width: 100%;
        border: 1px solid #d1d7e8;
        border-radius: 8px;
        height: 40px;
        box-sizing: border-box;
        box-shadow: none !important;

        &.disabled {
          color: var(--gray);
        }

        &:hover {
          box-shadow: none !important;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    &.open {
      background: transparent;
    }

    &.open .input-icon-dropdown {
      transition: transform 200ms ease;
      transform: rotate(180deg);
    }

    &:hover {
      background-color: unset;

      & path {
        stroke: #000;;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("/static/vendor/intlTelInput/img/flags@2x.png");}
}

.yt_input-hint {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  color: var(--gray);
}
.yt_input--error .yt_input-hint {
  color: var(--red);
}

.yt_input-container {
  --side-padding: 16px;
  border: 1px solid #D1D7E8;
  border-radius: 8px;
  min-height: 56px;
  display: flex;
  align-items: stretch;
  padding: 0 var(--side-padding, 16px);
  transition: border-color 200ms ease;
  box-sizing: border-box;
  cursor: text;
}
.yt_input--disabled .yt_input-container {
  background: #F6F7FA;
}
.yt_input--error:not(.yt_input--disabled) .yt_input-container {
  border-color: var(--red);
}

.yt_input-field {
  width: 100%;
  position: relative;
}

.yt_input--error:not(:focus-within, .yt_input--disabled) .yt_input-label {
  color: var(--red);
}

.yt_input-container:focus-within {
  outline: none;
  border-color: var(--black);
}

.yt_input-icons {
  display: flex;
  align-items: center;
  right: var(--side-padding);
}
.yt_input-icons--right {
  right: unset;
  left: var(--side-padding);
}

.yt_input-icon {
  cursor: pointer;
  user-select: none;
  display: flex;
}
.yt_input-icon--eye:not(:last-child) {
  margin-right: 12px;
}
.yt_input-icon--clear:first-child,
.yt_input-icon--eye:first-child {
  margin-left: 8px;
}

.yt_input-icon--search {
  margin-right: 8px;
  pointer-events: none;
}

.yt_input-icon--dropdown {
  transition: transform 200ms ease;
}
.yt_input:focus-within .yt_input-icon--dropdown  {
  transform: rotate(180deg);
}

.yt_input--textarea .yt_input-container {
  height: 88px;
}
.yt_input--solo .yt_input-form--textarea {
  height: calc(100% - 19px);
}

.yt_input:not(.yt_input--solo) {
  --input-padding-top: 28px;
}
.yt_input-form--textarea {
  height: calc(100% - var(--input-padding-top, 19px));
  font-family: var.$font-family-sf-pro-text;
}

.yt_input-form {
  font-family: var.$font-family-sf-pro-text;
  width: 100%;
  border: none;
  outline: none;
  background: none;
  color: var(--black);
  padding: 0;
  position: absolute;
  top: 19px;
  font-size: 14px;
  line-height: 18px;
  transform-origin: bottom left;
}
.yt_input-form--textarea {
  resize: none;

  &::-webkit-scrollbar-thumb {
    background: var.$gray-dark;
    border-radius: var.$rounded-16;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
}
.yt_input--phone .yt_input-form {
  position: unset;
  box-shadow: none;
}
.yt_input--phone .yt_input-field {
  display: flex;
  position: unset;
}
.yt_input--phone .yt_input-container {
  position: relative;
}
.yt_input--date .yt_input-form {
  width: max-content;
}

.yt_input--disabled {
  & input, textarea {
    color: var(--gray);
  }
}

.yt_input-label {
  transform-origin: top left;
  transition: transform 150ms ease;
  color: var(--gray);
  font-size: 14px;
  line-height: 18px;
  top: 19px;
}

.yt_input--solo .yt_input-form {
  top: 19px
}

.yt_input--hasvalue .yt_input-label,
.yt_input:focus-within .yt_input-label {
  transform: scale(.875) translateY(-9px);
  line-height: 16px;
}

.yt_input--hasvalue:not(.yt_input--solo, .yt_input--phone) .yt_input-form,
.yt_input:not(.yt_input--solo, .yt_input--phone):focus-within .yt_input-form {
  top: unset;
  bottom: 10px;
}

.yt_input--hasvalue:not(.yt_input--solo) .yt_input-form--textarea,
.yt_input:not(.yt_input--solo):focus-within .yt_input-form--textarea {
  top: 28px;
}


.yt_input--solo.yt_input--hasvalue .yt_input-label,
.yt_input--solo:focus-within .yt_input-label {
  display: none;
}

.yt_input-label {
  position: absolute;
}

.yt_input--error:not(:focus-within) >
.yt_input-icons >
.yt_input-icon--clear >
svg >
path {
  stroke: var(--red);
}
</style>
