<template>
  <div class="popup-help-me">
    <div v-if="!orderAdded && !wait">
      <div class="popup-help-me__header u-popup-header">
        {{ $t('bookingPopup.popup.helpMe.title') }}
      </div>
      <div class="popup-help-me__descr u-popup-desc" v-html="$t('bookingPopup.popup.helpMe.desc')">
      </div>
      <div class="popup-help-me__note">
        {{ $t('bookingPopup.popup.helpMe.note') }}
      </div>
      <div class="popup-help-me__tabs">
        <yt-tabs
          :tabs="tabs"
          :selected-index="selectedIndex"
          @select="selectTab"
          class="popup-help-me__tabs-panel"
        />
        <div class="popup-help-me__tabs-content">
          <yt-input
            v-show="selectedIndex < 2"
            class="popup-help-me__tabs-content-item"
            type="phone"
            ref="phone"
            v-model="userContact.personal_phone"
            :error="$v.userContact.personal_phone.$error"
            @input="changeField('personal_phone', $event)"
            :hint="$v.userContact.personal_phone.$error ? $t('bookingPopup.popup.helpMe.wrongPhone') : ''"
          />
          <yt-input
            v-if="selectedIndex === 2"
            class="popup-help-me__tabs-content-item"
            placeholder="E-mail"
            :solo="true"
            :value="userContact.email"
            :error="$v.userContact.email.$error"
            @input="changeField('email', $event)"
            :hint="$v.userContact.email.$error ? $t('bookingPopup.popup.sandContact.error.email') : ''"
          />
        </div>
      </div>
      <yt-button
        class="popup-help-me__submit"
        color="green"
        @click="submitPopup"
      >
        {{ $t('bookingPopup.popup.helpMe.btn') }}
      </yt-button>
      <div class="u-popup-note" >
        {{$t('bookingPopup.popup.termsGeneral.text1')}}
        <a href="/help/public-offer" target="_blank">{{$t('bookingPopup.popup.termsGeneral.textOffer')}}</a>
        {{$t('bookingPopup.popup.termsGeneral.text2')}}
        <a href="/help/privacy-agree" target="_blank">
          {{$t('bookingPopup.popup.termsGeneral.textPrivacy')}}
        </a>
      </div>
    </div>
    <div class="popup-help-me__wait" v-if="wait || errorMessage.length ">
      <icon v-if="!errorMessage.length" class="popup-help-me__circle" icon="loader-circle"/>
      <div v-if="errorMessage.length" v-html="errorMessage"></div>
    </div>
    <div v-if="orderAdded && !wait">
      <div class="popup-help-me__header u-popup-header" v-html="$t('bookingPopup.popup.helpMe.result.title')"></div>
      <div class="popup-help-me__descr u-popup-desc" v-html="$t('bookingPopup.popup.helpMe.result.text')">
      </div>
    </div>
  </div>
</template>
<script>
import YtTabs from "@/components/ui-kit/yt-tab";
import YtInput from "@/components/ui-kit/yt-input";
import YtButton from "@/components/ui-kit/yt-button"
import Icon from "@/components/icons/index.vue";
import {mapGetters, mapState} from "vuex";
import { ytEvents } from "@/plugins/analytics";
import {axiosUser} from "@/services/scopes/axios-user";
import {axiosOrders} from "@/services/scopes/axios-orders"
import {email, requiredIf} from "vuelidate/lib/validators";
import { ytVueGlobalStore } from "@/services/yt-vue-global-store.js";

export default {
  components: {
    YtTabs,
    YtInput,
    YtButton,
    Icon
  },
  data() {
    const language = ytVueGlobalStore.instantGet('language');
    return {
      visible: false,
      tabs: [
        {label: (language?.id == 'ru' ? 'Телефон' : 'Phone')},
        {label: 'WhatsApp'},
        {label: 'Email'}
      ],
      selectedIndex: 0,
      orderAdded: false,
      wait: true,
      errorMessage: "",
      userContact: {
        personal_phone: "",
        email: "",
      }
    };
  },
  validations() {
    return {
      userContact: {
        personal_phone: {
          required: requiredIf(() => this.selectedIndex < 2 ),
          minLength(value) {
            if ( this.selectedIndex < 2){
              return value.replace(/ /g,'').length >= 12;
            }
            else {
              return true;
            }
          }
         // minLength: minLength(10),
        },
        email: {
          required: requiredIf(() => this.selectedIndex === 2 ),
          email,
        },
      }
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    ...mapGetters("user", ["getUserLoginStatus", "getUserContact"]),
  },
  methods: {
    openByEvent() {
      this.visible = true;
    },
    selectTab(evt) {
      this.selectedIndex = evt;
    },
    changeField(field, evt) {
      this.userContact[field] = evt
    },
    async submitPopup() {
      this.$v.$touch();
      if(this.$v.userContact.$error) {
        return;
      }
      this.wait = true;
      if(!this.getUserLoginStatus){
        let value;
        if(this.selectedIndex < 2) {
          value = { personal_phone: this.userContact.personal_phone.replaceAll(' ', '').replaceAll('+', ''),}
        }
        else {
          value = { email:  this.userContact.email,}
        }
        await axiosOrders.updateUserData({
          "value" : value
        }).catch(function(error) {
          console.log("error", error);
        });
      }
      await axiosOrders.orderAdd('helpMe', {
        "tourId": 0,
        "peopleCounter": 0,
        "date": 0,
        "from": "",
        "to": "",
        "message": "",
        "type": "helpMe",
        "contact_type": this.getContactType(),
        "user": {
          "email": this.selectedIndex < 2 ? "" : this.userContact.email,
          "phone": this.selectedIndex < 2 ? this.userContact.personal_phone.replaceAll(' ', '').replaceAll('+', '') : ''
        }
      })
        .then(({data}) => {
          if(data.success){
            this.orderAdded = true;
            this.wait = false;
            ytEvents.track('want_to_go', 'help_me');
          }
        })
        .catch(({response}) => {
          this.errorMessage = response.data.data.message
        });
    },
    getContactType() {
      switch (this.selectedIndex) {
        case 0: return 'Phone';
        case 1: return 'Messenger';
        case 2: return 'Email';
      }
    },
  },
  mounted() {
    if (this.getUserLoginStatus && this.visible ) {
      if(this.getUserContact.personalPhone.length == 0 || this.getUserContact.email.length == 0) {
        axiosUser.userInfo()
          .then(({data}) => {
            if (data.success) {
              if(data.data.personalPhone) {
                this.userContact.personal_phone = data.data.personalPhone;
              }
              this.userContact.email = data.data.email;
            }
          })
          .catch(function(error) {
            console.log("error", error);
          });
      } else {
        this.userContact.personal_phone = this.getUserContact.personalPhone;
        this.userContact.email = this.getUserContact.email;
      }
    }

    if (!this.getUserLoginStatus) {
      axiosOrders.getUserData()
        .then(({data}) => {
          if (data.success) {
            if(data.data.personal_phone) {
              this.userContact.personal_phone = data.data.personal_phone
            }
            this.userContact.email = data.data.email || "";
          }
        })
        .catch(function(error) {
          console.log("error", error);
        });
    }
    this.wait = false;
    this.$emit('loaded');
  },
}
</script>
<style lang="scss" scoped>
.popup-help-me {
  padding: 8px;
  webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &__header {
    margin-bottom: 27px;
  }

  &__descr {
    margin-bottom: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.32px;
  }

  &__note {
    color: var.$secondary;
    margin-bottom: 20px;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px; /* 130.769% */
    letter-spacing: -0.26px;
  }

  &__tabs-panel {
    margin-bottom: 20px;
  }

  &__tabs-content {
    width: 100%;
    margin-bottom: 20px;

    &-item {
      width: 100% !important;
    }
  }

  &__submit {
    width: 100%;
    margin-bottom: 16px;
  }

  &__wait{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__circle{
    animation: spin 1s linear infinite;
  }
}
@keyframes spin {
  from {transform:rotate(360deg);}
  to {transform:rotate(0deg);}
}

.popup-help-me a{
  color: inherit!important;
  text-decoration: underline;
}
</style>
